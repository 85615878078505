<div class="w3l-bootstrap-header fixed-top">
  <nav class="navbar navbar-expand-lg navbar-light p-2">
    <div class="container">
      <!-- <a class="navbar-brand" [routerLink]="['']"><img src="assets/images/android-chrome-192x192.png" alt="">TED
        Research
        solutions</a>
       if logo is image enable this   -->
      <a class="navbar-brand">
        <img src="assets/images/logo_image.png" alt="TED Research Solutions" title="TED Research Solutions"
          style="width:4rem;" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded=true aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">

        <div class="form-inline">
          <!-- <a href="#" class="login mr-4">Log in</a>
              <a href="#" class="btn btn-primary btn-theme">Create Free Account</a> -->
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <a class="nav-link" [routerLink]="['']" (click)="closeMenu()">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['categories']" (click)="closeMenu()">Report categories</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['about']" (click)="closeMenu()">About Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['contact']" (click)="closeMenu()">Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</div>
